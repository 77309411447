import firebase from "firebase";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";

import "../utils/config";
import bowser from "bowser";
import { DateTime } from "luxon";

let firebaseui = require("firebaseui");

// Initiate Firebase Auth.
function initFirebaseAuth() {
	// Listen to auth state changes.
	firebase
		.auth()
		.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
		.then((r) => {});
	firebase.auth().onAuthStateChanged(authStateObserver);
}

// Returns true if a user is signed-in.
function isUserSignedIn() {
	return !!firebase.auth().currentUser;
}

function getUserEmail() {
	return firebase.auth().currentUser.email;
}

// Returns the signed-in user's display name.
function getUserName() {
	return firebase.auth().currentUser.displayName;
}

function signOut() {
	// Sign out of Firebase.
	firebase
		.auth()
		.signOut()
		.then((r) => {});
}

/*==========================Login Troubleshooting Instructions==========================*/

let current_browser_string = check_browser();
// if (current_browser_string == 'Safari') { alert("Please enable popups for this page through your browser settings"); }

let login_buttonModal = document.getElementById("login_button_modal");
let login_buttonClose = document.getElementById("login_button_close");
let login_buttonButton = document.getElementById("login_button_button");
login_buttonButton.addEventListener("click", function () {
	firebase.analytics().logEvent("login_button_button", {
		content_type: "click",
	});

	showElement(login_buttonModal, true);
	if (
		/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		)
	) {
		document.getElementById("login_button_instruction_mobile").style.display =
			"block";
	} else {
		document.getElementById("login_button_instruction_desktop").style.display =
			"block";
	}
});
login_buttonClose.addEventListener("click", function () {
	showElement(login_buttonModal, false);
	document.getElementById("login_button_instruction_mobile").style.display =
		"none";
	document.getElementById("login_button_instruction_desktop").style.display =
		"none";
});
// When the user clicks anywhere outside of the modal, close it
window.onclick = function (event) {
	switch (event.target) {
		case login_buttonModal:
			showElement(login_buttonModal, false);
			break;
		case actualLoginModal:
			showModalWithFirebaseUi(false);
			break;
	}
};

/**
 * Helper to show or hide an element
 *
 * @param {Node} element
 * @param {Boolean} show
 */
function showElement(element, show) {
	element.style.visibility = show ? "visible" : "hidden";
	element.style.opacity = show ? 1 : 0;
}

function check_browser() {
	let result = bowser.getParser(window.navigator.userAgent);
	console.log(result);
	console.log(
		"You are using " +
			result.parsedResult.browser.name +
			" v" +
			result.parsedResult.browser.version +
			" on " +
			result.parsedResult.os.name
	);
	return result.parsedResult.browser.name;
}

/*==========================Log in Logic==========================*/

/**
 * Creates the firebaseui auth instance
 */

const ui = new firebaseui.auth.AuthUI(firebase.auth());

/**
 * Runs the firebaseui auth instance with specified attributes on the
 * #firebaseui-auth element
 */
ui.start("#firebaseui-auth", {
	callbacks: {
		signInSuccessWithAuthResult: handleAuthRedirect,
		uiShown: firebaseUiLoaded,
	},
	// https://github.com/firebase/firebaseui-web#credential-helper; one tap signin
	credentialHelper: firebaseui.auth.CredentialHelper.NONE,
	signInFlow: "popup",
	// doesn't appear to work; used for handleAuthRedirect's redirectUrl
	signInSuccessUrl: "/homepage.html",
	signInOptions: [
		firebase.auth.EmailAuthProvider.PROVIDER_ID,
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
	],
});

/**
 * Refers to the hidden modal that displays the Sign in with Password
 * screen
 */
const actualLoginModal = document.getElementById("actual_login_modal");

/**
 * Refers to the close button of the hidden modal `actualLoginModal`
 */
const actualLoginClose = document.getElementById("actual_login_close");

/**
 * Closes the FirebaseUi modal
 */
actualLoginClose.addEventListener("click", function () {
	showModalWithFirebaseUi(false);
});

/**
 * Reference to the #firebaseui-auth element
 */
let firebaseUiAuth;

/**
 * Shows and hides the FirebaseUi modal appropriately; dependent on
 * the inner content of the FirebaseUi library to proceed.
 * Rather dangerous to reference 3rd party librarie elements by class
 * but the only way to customize this library as no api for
 * customization was provided.
 * Worst case scenario is that modal doesn't open properly but login
 * still will continue fine on the main page.
 *
 * @param {Boolean} show whether to show or hide the modal
 */
function showModalWithFirebaseUi(show) {
	showElement(actualLoginModal, show);
	firebaseUiAuth.remove();

	if (show) {
		document
			.getElementById("actual_login_modal_content")
			.appendChild(firebaseUiAuth);
	} else {
		document
			.getElementById("firebaseui-auth-container")
			.appendChild(firebaseUiAuth);

		const recoveryEmailContainer = document.querySelector(
			".firebaseui-id-page-password-recovery-email-sent"
		);
		const withPasswordContainer = document.querySelector(
			".firebaseui-id-page-password-sign-in"
		);

		const cancelButton = document.querySelector(
			".firebaseui-id-secondary-link"
		);
		const submitButton = document.querySelector(".firebaseui-id-submit");

		if (recoveryEmailContainer) {
			submitButton?.click();
		} else if (withPasswordContainer) {
			cancelButton?.click();

			setTimeout(() => {
				// intentional; this happens when user needs to fill out password
				// and flow needs to navigate back two screens
				document.querySelector(".firebaseui-id-secondary-link")?.click();
			});
		} else {
			cancelButton?.click();
		}

		setTimeout(addListenerToSignInButton);
	}
}

/**
 * Adds a listener to the Sign In with email button to handle its
 * movement with the modal
 */
function addListenerToSignInButton() {
	const signInWithEmailButton = document.querySelector(
		'.firebaseui-id-idp-button[data-provider-id="password"]'
	);
	signInWithEmailButton?.addEventListener("click", () => {
		showModalWithFirebaseUi(true);
	});
}

/**
 * Function that is run when FirebaseUi is first shown on the screen
 *
 *  - setTimeout is used to add this function to the queue otherwise
 *    it won't work
 */
function firebaseUiLoaded() {
	setTimeout(() => {
		firebaseUiAuth = document.getElementById("firebaseui-auth");

		const isSignIn = firebaseUiAuth.children[0]?.classList.contains(
			"firebaseui-id-page-sign-in"
		);
		showElement(firebaseUiAuth, true);

		if (isSignIn) {
			// deals with the local-dev refresh case
			showModalWithFirebaseUi(true);
		} else {
			// if page doesn't refresh on clicking email signin
			addListenerToSignInButton();
		}
	});
}

function handleAuthRedirect(auth, redirectUrl) {
	// this IIFE ensures that this function's return statement
	// will still be respected
	(async () => {
		redirectUrl = redirectUrl ?? "/homepage.html";

		const enteredUser = auth.user;

		const userRef = firebase
			.firestore()
			.collection("users")
			.doc(enteredUser.uid);
		const user = await userRef.get();

		// check if there is an existing user
		if (user.exists) {
			window.location = redirectUrl;
		} else {
			await userRef.set({
				name: enteredUser.displayName,
				email: enteredUser.email,
				uid: enteredUser.uid,
				profilePicUrl:
					enteredUser.photoURL || "/images/profile_placeholder.png",
				start_date: DateTime.now().toISO(),
			});

			window.location = redirectUrl;
		}
	})();

	return false;
}

/*==========================Main==========================*/
initFirebaseAuth();

firebase.analytics();

// Triggers when the auth state change for instance when the user signs-in or signs-out.
async function authStateObserver(currentUser) {
	if (currentUser) {
		// this block should rarely run, only if the user directs
		// themselves to login if already logged in
		const userRef = firebase
			.firestore()
			.collection("users")
			.doc(currentUser.uid);
		const user = await userRef.get();
		// this check is necessary to ensure that handleAuthRedirect can
		// still run, since this function will run first; do nothing if no user
		if (user.exists) {
			// user is already signed in; redirect them where they need to be
			window.location = "./homepage.html";
		}
	} else {
		// user is signed out; show appropriate UI indicators
		login_buttonButton.style.display = "inline-block";
		document.getElementById("loadingDiv").style.display = "none";
	}
}
